import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ReactComponent as ArrowUp } from '../../svg/fa/arrow-left.svg';

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: none; // Initially hidden
  background-color: ${theme.colors.accent};
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 90;
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transform: translateY(-3px) rotate(90deg);    
  }

  ${theme.below.lg} { // Only visible on mobile
    display: block;
  }
`;

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebarHeight = document.querySelector('aside')?.offsetHeight || 0;
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > sidebarHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    isVisible && (
      <BackToTopButton onClick={scrollToTop}>
        <ArrowUp />
      </BackToTopButton>
    )
  );
};

export default BackToTop;