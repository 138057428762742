import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { useQuery } from 'react-apollo';
import AcademyQuery from './AcademyQuery.gql';
import AcademySidebarQuery from './AcademySidebarQuery.gql';
import { styled } from "linaria/react";
import { theme } from "../Theme";
import Image from '@jetshop/ui/Image';
import logo from '../../img/academy-logo.jpg';
import { ReactComponent as AngleDown } from '../../svg/fa/angle-down.svg';
import { ReactComponent as AngleRight } from '../../svg/fa/angle-right.svg';
import LoadingPage from '../LoadingPage';

const Sidebar = styled('aside')`
  a {
    color: #000;
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: ${theme.colors.accent};
      transition: all ease 0.3s;
    }
    &.active {
      font-weight: bold;
    }
  }
  ul {
    margin-left: 10px;
    font-size: 90%;
  }
`;

const Header = styled('div')`
  padding: 0 0 1rem .5rem;
  text-align: left;
  text-transform: uppercase;
  h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

// const Logo = styled('div')`
//   text-align: center;
//   padding: 3rem;
//   ${theme.below.lg} {
//     padding: 1.5rem;
//   }
//   ${theme.below.md} {
//     height: 35%;
//     margin: auto;
//     margin-bottom: 0.5rem;
//     padding: 1rem;
//     text-align: center;
//     width: 35%;
//   }
//   ${theme.below.xs} {
//     padding: 0;
//     margin-top: 0.25rem;
//     margin-bottom: 1rem;
//   }
// `;

const MainCat = styled('div')`
  li {
    border-top: 1px solid ${theme.colors.black};
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 0.3rem 0 0.5rem 0;
    text-transform: uppercase;
    span:first-of-type {
      display: block;
      padding-top: 6px;
    }
  }
`;

const SubCats = styled('li')`
  :last-of-type {
    margin-bottom: 0.75rem;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1rem;
  padding-right: 1px;
  svg {
    max-width: 1rem;
    max-height: 1rem;
  }
`;
const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <AngleDown /> : <AngleRight />}</IndicatorIcon>
);

const AcademySidebar = ({ path, pathname }) => {
  const { data, loading, error } = useQuery(AcademySidebarQuery, {
    variables: {
      pathname: path,
    },
  });
  if (error) return null;
  if (loading) return <LoadingPage />;

  const {
    route: { object },
  } = data;

  return (
    <Sidebar>
      <Header>
        <Link to="/hooks-academy">
          <h2>Hööks Academy</h2>
        </Link>
      </Header>
      <Accordion single>
        {({ openIndexes, handleClick, AccordionContent }) => (
          <>
            {object.subcategories.map((category, index) => (
              <div key={index}>
                <ul>
                  <Link
                    to={category.primaryRoute.path}
                    onClick={() => {
                      handleClick(index);
                    }}
                  >
                    <MainCat>
                      <li>
                        <span>{category.name}</span>
                      </li>
                    </MainCat>
                  </Link>
                </ul>
                {category.subcategories && category.subcategories.length > 0 && (
                  <AccordionContent
                    isOpen={
                      openIndexes.includes(index) ||
                      pathname.indexOf(category.primaryRoute.path) > -1
                    }
                  >
                    <ul>
                      {category.subcategories.map((subCat, subIndex) => (
                        <SubCats key={subIndex}>
                          <Link
                            className={
                              subCat?.primaryRoute?.path === pathname ? 'active' : null
                            }
                            to={subCat?.primaryRoute?.path}
                          >
                            {subCat?.name}
                          </Link>
                          {subCat.subcategories && subCat.subcategories.length > 0 && (
                            <Accordion single>
                              {({ openIndexes: subOpenIndexes, handleClick: subHandleClick, AccordionContent: SubAccordionContent }) => (
                                <>
                                  <SubAccordionContent
                                    isOpen={
                                      subOpenIndexes.includes(subIndex) ||
                                      pathname.indexOf(subCat.primaryRoute.path) > -1
                                    }
                                  >
                                    <ul>
                                      {subCat.subcategories.map((subSubCat, subSubIndex) => (
                                        <SubCats key={subSubIndex}>
                                          <Link
                                            className={
                                              subSubCat?.primaryRoute?.path === pathname ? 'active' : null
                                            }
                                            to={subSubCat?.primaryRoute?.path}
                                            onClick={() => {
                                              subHandleClick(subSubIndex);
                                            }}
                                          >
                                            <span>{subSubCat?.name}</span>
                                          </Link>
                                        </SubCats>
                                      ))}
                                    </ul>
                                  </SubAccordionContent>
                                </>
                              )}
                            </Accordion>
                          )}
                        </SubCats>
                      ))}
                    </ul>
                  </AccordionContent>
                )}
              </div>
            ))}
          </>
        )}
      </Accordion>
    </Sidebar>
  );
};

export default AcademySidebar;
