import React from 'react';

import { useQuery } from 'react-apollo';
import AcademyQuery from './AcademyQuery.gql';

import { styled } from "linaria/react";

import { theme } from "../Theme";
import Head from '@jetshop/core/components/Head';

import AcademySidebar from './AcademySidebar';
import AcademyGrid from './AcademyGrid';
import AcademyContentRenderer from './AcademyContentRenderer';

import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image';
import logo from '../../img/academy-logo.jpg';
import t from 'format-message';
import LoadingPage from '../LoadingPage';
import BackToTop from './BackToTop';

export const AcademyContainer = styled(MaxWidth)`
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: auto 40px  1fr;
  grid-template-areas:
    "sidebar content"
    "sidebar grid-header"
    "sidebar grid";
  margin-top: 4rem;
  margin-bottom: 4rem;
  aside {
    grid-area: sidebar;
  }
  > ul {
    margin-left: 2rem;
    width: calc(80% - 2rem);
  }
  ${theme.below.lg} {
    display: flex;
    flex-direction: column;
    aside {
      max-width: 750px;
    }
  }
  ${theme.below.md} {
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 4rem;
    aside {
       padding-right: 1rem;
    }
    > ul {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 750px;
  margin: 0 0 2rem 2rem;
  min-height: 5px;
  align-items: center;
  ${theme.below.lg} {
    margin: 0;
  }
  .text-content {
    padding: 1rem;
  }
  .contentComponent {
    .image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        object-fit: contain !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
    .content-positioner {
      padding: 1rem !important;
      h2 {
        margin-bottom: 1rem;
        margin-top: 0;
        line-height: 1;
      }
    }
  }
   
`;

const GridHeader = styled('div')`
  margin: 0 0 2rem 3rem;
  grid-area: grid-header;
`;

const GridContainer = styled('div')`
  grid-area: grid;
`;

// Maintenance page styled components
const MaintenanceContainer = styled('div')`
  background-color: ${theme.colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 15rem 0;
  text-align: center;
  ${theme.below.md} {
    padding: 1rem 1rem 15rem 1rem;
  }
`;

const Logo = styled('div')`
  margin-bottom: 2rem;
  width: 200px;

  ${theme.below.md} {
    width: 150px;
  }
`;

const Message = styled('p')`
  max-width: 500px;
  line-height: 1.5;
  margin: 0 auto;
  font-size: 1.2rem;

  ${theme.below.md} {
    font-size: 1rem;
  }
`;

const AcademyPage = ({ location: { pathname }, match: { path }, ...props }) => {
  const { data, loading, error } = useQuery(AcademyQuery, {
    variables: {
      pathname
    }
  });  
 
  //Temporarily return maintenance page while content is being restructured
  // return (
  //   <MaintenanceContainer>
  //     <Logo>
  //       <Image src={logo} alt="Hööks Academy" />
  //     </Logo>
  //     <Message>
  //       {t('We are remodeling our Hööks Academy pages to give you an even better experience. The new and improved Academy will launch shortly.')}
  //     </Message>
  //   </MaintenanceContainer>
  // );

  //Original component code commented out during content restructuring
  if (error) return null;
  if (loading) return <LoadingPage />;
  const {
    route: { object }
  } = data;
  const category = object;
  var posts = [];
  if (path === pathname) {
    for (let i = 0; i < category.subcategories.length; i++) {
      const subCat = category.subcategories[i];
      posts = posts.concat(subCat.subcategories);
    }
  } else {
    posts = category.subcategories;
  }
  const items = category?.data?.items;
  const categoryName = category?.name;
  return (
    <AcademyContainer>
      <Head data={category.head} />
      <AcademySidebar path={path} pathname={pathname} />
       { items?.length > 0 && (
        <ContentContainer>
          <AcademyContentRenderer content={items} />
        </ContentContainer>
      )}
      { posts?.length > 0 && (
        <>
          <GridHeader>
            <h2>{categoryName}</h2>
          </GridHeader>
          <GridContainer>
            <AcademyGrid posts={posts} category={category} />
          </GridContainer>
        </>
      )}
     
      <BackToTop />
    </AcademyContainer>
  );
};

export default AcademyPage;
