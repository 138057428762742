import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allComponents } from '../StartPage/ContentRendererComponents/allcomponents';

const AcademyContentRenderer = ({ content }) => {
  return (
    <ContentRenderer
      items={content}
      components={allComponents}
    />
  )
};

export default AcademyContentRenderer;
